import CommonStore from './common';
import { account } from '@/api';
import type { FleAppEnv } from '@/types/const/serviceEnv';
import Cookies from 'js-cookie';
const fleAppEnv: FleAppEnv = fleProcess.fleEnv || 'dev';
const envArr: any = {
  '180': {
    dev: 'dev-mall.icarp.vip/account/login',
    qa: 'qa-mall.icarp.vip/account/login',
    prod: 'mall.icarp.vip/account/login',
  },
  '190': {
    dev: 'dev-mall.wowcarp.com/account/login',
    qa: 'qa-mall.wowcarp.com/account/login',
    prod: 'mall.wowcarp.com/account/login',
  },
  '200': {
    dev: 'dev-dist.icarp.vip/account/login',
    qa: 'qa-dist.icarp.vip/account/login',
    prod: 'dist.icarp.vip/account/login',
  },
};
const filter = (logintype: any, type: string) => {
  if (logintype == '190') {
    if (type == 'loginStepTwo') {
      if (CommonStore.pageQuery?.loginLink.includes(envArr[logintype][fleAppEnv])) {
        return account.customEmployeeMall;
      }
    }
    if (type == 'enterCompany') {
      if (CommonStore.pageQuery?.loginLink.includes(envArr[logintype][fleAppEnv])) {
        return account.entetConpanyMall;
      }
    }
  }
  if (logintype == '180') {
    if (type == 'systemId') {
      if (Cookies.get('loginLink') == envArr[logintype][fleAppEnv]) {
        return '100001';
      } else {
        return '100009';
      }
    }
  }
};
export { filter };
