import React, { useState, useEffect } from 'react';
import { StyleProvider, legacyLogicalPropertiesTransformer } from '@ant-design/cssinjs';
import { useParams, Outlet, useLocation, useNavigate } from 'react-router-dom';
import PlusConfigProvider from '@fle-ui/plus-config-provider';
import PlusProvider from '@fle-ui/plus-context';
import { App as AntdApp, ConfigProvider, Spin } from 'antd';
import { observer } from 'mobx-react';
import { useFavicon, useTitle } from 'ahooks';
import { Message } from '@/components';
import Application from '@/const/application';
import { CommonStore } from '@/stores';
import Theme from '@/theme';
import { getLoginLink } from '@/tools/common';
import { filter } from '@/stores/filterApi';
import type { ApplicationItem, LoginType } from '@/const/application';

import './index.less';

const Layout: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { code } = useParams<{ code: string }>();

  const { Provider } = PlusProvider;
  const { pageQuery } = CommonStore;
  const [applicationConfig, setApplicationConfig] = useState<ApplicationItem>();
  const [showLoading, setShowLoading] = useState(true);

  useFavicon(pageQuery?.backendLogo || applicationConfig?.logo || '');
  useTitle(pageQuery?.portalName || applicationConfig?.title || '登录');

  useEffect(() => {
    const loginLink = getLoginLink(code);
    const loginPageQuery = async () => await CommonStore.loginPageQuery({ loginLink });
    loginPageQuery();

    const times = setTimeout(() => {
      setShowLoading(false);
      clearTimeout(times);
    }, 300);
  }, []);
  useEffect(() => {
    if (pageQuery?.loginType) {
      const application: any =
        Application[pageQuery.loginType as LoginType] || Application['90'] || Application['200'];
        // 处理180下的systemId
        if (pageQuery.loginType == '180') {
        application.systemId = filter('180', 'systemId');
      }
      console.log(application.systemId)
      setApplicationConfig(application);
    }
  }, [pageQuery]);

  useEffect(() => {
    // 对不允许注册的平台进行重定向拦截
    if (
      location.pathname.indexOf('register') > -1 &&
      applicationConfig &&
      !applicationConfig?.showRegister
    ) {
      navigate('/login', { replace: true });
    }
  }, [location, applicationConfig?.showRegister]);

  return (
    <PlusConfigProvider
      prefixCls="jack"
      theme={{
        ...Theme,
        token: {
          colorLink: applicationConfig?.styleToken?.themeColor || '#2c68ff',
          colorPrimary: applicationConfig?.styleToken?.themeColor || '#2c68ff',
        },
      }}
    >
      <StyleProvider hashPriority="high" transformers={[legacyLogicalPropertiesTransformer]}>
        <ConfigProvider>
          <AntdApp className={applicationConfig?.className || ''}>
            <Provider value={{ applicationConfig, code }}>
              {showLoading && (
                <div
                  style={{
                    width: '100vw',
                    height: '100vh',
                    backgroundColor: '#fff',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    zIndex: 9999,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Spin size="large" />
                </div>
              )}
              <Outlet />
            </Provider>
            <Message />
          </AntdApp>
        </ConfigProvider>
      </StyleProvider>
    </PlusConfigProvider>
  );
};

export default observer(Layout);
