import { LockOutlined, MobileOutlined, UserOutlined, KeyOutlined } from '@ant-design/icons';
import { filter } from '@/stores/filterApi';
import type { FormRule } from 'antd';

// 40: 会员子供应商端 90: 智鲤  100: 万鲤商联 120: 百鲤跃川 140: 百鲤跃川客服平台  150: 妙鲤运营后台  160: 智鲤运营后台  170: 智鲤运营后台 180: 分销商城登录 190：妙货，200:经销商
export type LoginType =
  | '40'
  | '90'
  | '100'
  | '120'
  | '140'
  | '150'
  | '160'
  | '170'
  | '180'
  | '190'
  | '200';

export type LoginMode = 'account' | 'mobile';

type FormItemType = 'account' | 'mobile' | 'password' | 'code';

type FormItemConfig = {
  [key in FormItemType]?: {
    name: string;
    minLength?: number;
    maxLength?: number;
    prefix?: React.ReactNode;
    placeholder?: string;
    rules?: FormRule[];
  };
};

export interface ApplicationItem {
  loginType: LoginType; // 登录类型 40：会员子供应商端  90：百鲤跃川  100：万鲤商联
  logo: string; // logo
  title: string;
  systemId: any; // 平台ID
  tokenKey: string; // cookies 设置的名称
  setDomin: boolean; // cookies 是否设置domin
  showRegister: boolean; // 是否显示注册按钮
  showForgetPwd: boolean; // 是否显示忘记密码按钮
  loginStep: number; // 登录步骤，是一步完成登录还是需要两步
  loginApi: {
    account: string;
    mobile?: string;
  }; // 登录调用的接口
  loginMode: Array<{
    key: LoginMode;
    label: string;
  }>; // 登录方式
  formItemConfig: Partial<FormItemConfig>;
  registerBanner?: string; // 注册banner
  userHref?: string; // 用户协议协议
  createHref?: string; // 创建团队协议
  privacyHref?: string; // 隐私声明协议
  styleToken?: {
    themeColor?: string;
  };
  defaultLoginPath?: string;
  className?: string;

  defaultParams?: {
    [key: string]: any;
  };
  checkLoginErrorModalConfig?: {
    banner: string;
    logo: string;
  };
}

type ApplicationProps = {
  [key in LoginType]?: ApplicationItem;
};

const DefaultCheckLoginErrorModalConfig = {
  banner: 'https://oss.elebuys.com/202408071812360009908319.png',
  logo: 'https://oss.elebuys.com/202408071814150009914638.png',
};

const CarpdanceCommonConfig: Omit<ApplicationItem, 'loginType' | 'title' | 'systemId'> = {
  tokenKey: 'token',
  loginStep: 2,
  setDomin: false,
  showRegister: true,
  showForgetPwd: true,
  loginApi: {
    account: 'loginAccount',
    mobile: 'loginMobile',
  },
  loginMode: [
    {
      key: 'account',
      label: '密码登录',
    },
    {
      key: 'mobile',
      label: '验证码登录',
    },
  ],
  formItemConfig: {
    account: {
      name: 'mobile',
      maxLength: 11,
      placeholder: '请输入手机号',
      prefix: <MobileOutlined className="fx__login-prefixIcon" />,
      rules: [
        {
          required: true,
          message: '请输入手机号',
        },
        {
          pattern: /^1\d{10}$/,
          message: '手机号格式错误',
        },
      ],
    },
    mobile: {
      name: 'mobile',
      maxLength: 11,
      placeholder: '请输入手机号',
      prefix: <MobileOutlined className="fx__login-prefixIcon" />,
      rules: [
        {
          required: true,
          message: '请输入手机号',
        },
        {
          pattern: /^1\d{10}$/,
          message: '手机号格式错误',
        },
      ],
    },
    password: {
      name: 'password',
      placeholder: '请输入密码',
      prefix: <LockOutlined className="fx__login-prefixIcon" />,
      rules: [
        {
          required: true,
          message: '请输入密码',
        },
      ],
    },
    code: {
      name: 'smsCode',
      maxLength: 6,
      placeholder: '请输入验证码',
      prefix: <KeyOutlined className="fx__login-prefixIcon" />,
      rules: [
        {
          required: true,
          message: '请输入验证码！',
        },
      ],
    },
  },
  logo: 'https://oss.elebuys.com/logo/carpdance/carpdance-logo.png',
  registerBanner: 'https://oss.elebuys.com/tmpdir/202311011942280003734108.png',
  createHref: 'https://store.fxqifu.com/front-end/#/article?store_article_id=618',
  userHref: 'https://store.fxqifu.com/front-end/#/article?store_article_id=616',
  privacyHref: 'https://store.fxqifu.com/front-end/#/article?store_article_id=617',
  checkLoginErrorModalConfig: DefaultCheckLoginErrorModalConfig,
};

const Application: ApplicationProps = {
  '40': {
    loginType: '40',
    title: '供应商平台',
    systemId: '100005',
    logo: 'https://oss.elebuys.com/logo/carpdance/carpdance-logo.png',
    tokenKey: 'supplier_token',
    loginStep: 1,
    setDomin: false,
    showRegister: false,
    showForgetPwd: false,
    loginApi: {
      account: 'supplierLogin',
    },
    defaultParams: {
      authType: 1,
      platform: 3,
      customerType: '40',
    },
    formItemConfig: {
      account: {
        name: 'userName',
        maxLength: 36,
        placeholder: '请输入登录账号',
        prefix: <UserOutlined className="fx__login-prefixIcon" />,
        rules: [
          {
            required: true,
            message: '请输入登录账号',
          },
        ],
      },
      password: {
        name: 'password',
        placeholder: '请输入密码',
        prefix: <LockOutlined className="fx__login-prefixIcon" />,
        rules: [
          {
            required: true,
            message: '请输入密码',
          },
          // {
          //   pattern: /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,16}$/,
          //   message: '密码须为8-16位且包含字母、数字、特殊符号',
          // },
        ],
      },
    },
    loginMode: [
      {
        key: 'account',
        label: '密码登录',
      },
    ],
    checkLoginErrorModalConfig: DefaultCheckLoginErrorModalConfig,
  },
  '90': {
    ...CarpdanceCommonConfig,
    loginType: '90',
    title: '智鲤',
    setDomin: false,
    systemId: '100001',
    logo: '//oss.elebuys.com/logo/smartercarp/smartercarp-logo.png',
    registerBanner: '//oss.elebuys.com/logo/smartercarp/registerBanner.png',
  },
  '100': {
    loginType: '100',
    title: '万鲤商联',
    systemId: '100003',
    logo: 'https://oss.elebuys.com/resource/elebuys/202401221352550000848728.png',
    tokenKey: 'token',
    loginStep: 2,
    setDomin: false,
    showRegister: true,
    showForgetPwd: true,
    loginApi: {
      account: 'loginAccount',
      mobile: 'loginMobile',
    },
    loginMode: [
      {
        key: 'account',
        label: '密码登录',
      },
      {
        key: 'mobile',
        label: '验证码登录',
      },
    ],
    formItemConfig: {
      account: {
        name: 'mobile',
        maxLength: 11,
        placeholder: '请输入手机号',
        prefix: <MobileOutlined className="fx__login-prefixIcon" />,
        rules: [
          {
            required: true,
            message: '请输入手机号',
          },
          {
            pattern: /^1\d{10}$/,
            message: '手机号格式错误',
          },
        ],
      },
      mobile: {
        name: 'mobile',
        maxLength: 11,
        placeholder: '请输入手机号',
        prefix: <MobileOutlined className="fx__login-prefixIcon" />,
        rules: [
          {
            required: true,
            message: '请输入手机号',
          },
          {
            pattern: /^1\d{10}$/,
            message: '手机号格式错误',
          },
        ],
      },
      password: {
        name: 'password',
        placeholder: '请输入密码',
        prefix: <LockOutlined className="fx__login-prefixIcon" />,
        rules: [
          {
            required: true,
            message: '请输入密码',
          },
        ],
      },
      code: {
        name: 'smsCode',
        maxLength: 6,
        placeholder: '请输入验证码',
        prefix: <KeyOutlined className="fx__login-prefixIcon" />,
        rules: [
          {
            required: true,
            message: '请输入验证码！',
          },
        ],
      },
    },
    registerBanner: 'https://oss.elebuys.com/resource/goods/materials/202401261508450002868346.png',
    createHref: 'https://store.fxqifu.com/front-end/#/article?store_article_id=614',
    userHref: 'https://store.fxqifu.com/front-end/#/article?store_article_id=612',
    privacyHref: 'https://store.fxqifu.com/front-end/#/article?store_article_id=613',
    checkLoginErrorModalConfig: DefaultCheckLoginErrorModalConfig,
  },
  '120': {
    ...CarpdanceCommonConfig,
    loginType: '120',
    title: '妙鲤',
    systemId: '100005',
    logo: '//oss.elebuys.com/logo/wowcarp/wowcarp-logo.png',
    registerBanner: '//oss.elebuys.com/operate/resource/202406111541170009332382.png',
    userHref: 'https://store.fxqifu.com/front-end/#/article?store_article_id=633',
    privacyHref: 'https://store.fxqifu.com/front-end/#/article?store_article_id=634',
  },
  '140': {
    ...CarpdanceCommonConfig,
    formItemConfig: {
      account: {
        name: 'userName',
        maxLength: 36,
        placeholder: '请输入登录账号',
        prefix: <UserOutlined className="fx__login-prefixIcon" />,
        rules: [
          {
            required: true,
            message: '请输入登录账号',
          },
        ],
      },
      password: {
        name: 'password',
        placeholder: '请输入密码',
        prefix: <LockOutlined className="fx__login-prefixIcon" />,
        rules: [
          {
            required: true,
            message: '请输入密码',
          },
        ],
      },
    },
    loginMode: [
      {
        key: 'account',
        label: '密码登录',
      },
    ],
    loginApi: {
      account: 'panguLogin',
    },
    showRegister: false,
    loginType: '140',
    title: '百鲤跃川客服平台',
    systemId: '100005',
  },
  '150': {
    ...CarpdanceCommonConfig,
    loginType: '150',
    title: '妙鲤运营后台',
    setDomin: false,
    systemId: '100002',
    showRegister: false,
    logo: '//oss.elebuys.com/logo/smartercarp/wowcarp-logo.png',
    registerBanner: '//oss.elebuys.com/logo/smartercarp/registerBanner.png',
    userHref: 'https://store.fxqifu.com/front-end/#/article?store_article_id=633',
    privacyHref: 'https://store.fxqifu.com/front-end/#/article?store_article_id=634',
  },
  '170': {
    ...CarpdanceCommonConfig,
    loginType: '170',
    title: '百鲤璇玑',
    setDomin: false,
    showRegister: false,
    systemId: '100006',
    userHref: '',
    privacyHref: '',
    logo: '//oss.elebuys.com/logo/fle-ai/fle-ai-logo.png',
    registerBanner: 'https://oss.elebuys.com/resource/goods/materials/202408221707050009781844.png',
    styleToken: {
      themeColor: '#006ffd',
    },
    className: 'fle-ai',
    defaultLoginPath: '/module/ai/home',
    checkLoginErrorModalConfig: {
      // 根据主题色适配
      banner: 'https://oss.elebuys.com/202408080959470000165133.png',
      logo: 'https://oss.elebuys.com/202408080959250000165121.png',
    },
  },
  '180': {
    ...CarpdanceCommonConfig,
    showRegister: false,
    showForgetPwd: true,
    loginStep: 2,
    loginApi: {
      // 分销商城登录接口
      account: 'loginAccountMall',
      mobile: 'loginMobileMall',
    },
    loginMode: [
      {
        key: 'account',
        label: '账号登录',
      },
    ],
    loginType: '180',
    title: '分销商城',
    setDomin: false,
    systemId: '100001',
    userHref: 'https://store.fxqifu.com/front-end/#/article?store_article_id=616',
    privacyHref: 'https://store.fxqifu.com/front-end/#/article?store_article_id=617',
    logo: '//oss.elebuys.com/logo/smartercarp/smartercarp-logo.png',
    styleToken: {
      themeColor: '#5292ff',
    },
    defaultLoginPath: '/module/showroom/mall',
  },
  '190': {
    ...CarpdanceCommonConfig,
    showRegister: false,
    showForgetPwd: true,
    loginStep: 2,
    loginType: '190',
    title: '登录通用',
    setDomin: false,
    systemId: '100008',
    userHref: 'https://store.fxqifu.com/front-end/#/article?store_article_id=633',
    privacyHref: 'https://store.fxqifu.com/front-end/#/article?store_article_id=634',
    logo: '//oss.elebuys.com/logo/smartercarp/smartercarp-logo.png',
    styleToken: {
      themeColor: '#5292ff',
    },
    defaultLoginPath: '/module/showroom/mall',
  },
  '200': {
    ...CarpdanceCommonConfig,
    showRegister: false,
    showForgetPwd: true,
    loginStep: 2,
    loginType: '200',
    title: '经销商登录',
    setDomin: false,
    systemId: '100009',
    userHref: 'https://store.fxqifu.com/front-end/#/article?store_article_id=616',
    privacyHref: 'https://store.fxqifu.com/front-end/#/article?store_article_id=617',
    logo: '//oss.elebuys.com/logo/smartercarp/smartercarp-logo.png',
    styleToken: {
      themeColor: '#5292ff',
    },
  },
};

export default Application;
